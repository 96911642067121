import { getAnalytics, isSupported } from '@firebase/analytics';
import { initializeApp } from 'firebase/app';
import { OAuthProvider } from 'firebase/auth';

const projectId = process.env.REACT_APP_PROJECT_ID;
const config = {
  projectId,
  appId: process.env.REACT_APP_APP_ID,
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};
const firebaseApp = initializeApp(config);

isSupported().then((analyticsSupported) => {
  analyticsSupported && getAnalytics(firebaseApp);
});

const firebaseOAuthProvider = new OAuthProvider(process.env.REACT_APP_OIDC_PROVIDER_ID);
firebaseOAuthProvider.setCustomParameters({ prompt: 'login' });

export { firebaseOAuthProvider };
