import { Paper, Typography } from '@mui/material';

import AuthBackground from '@components/auth-background';
import LoadingIndicator, { ElevatedLoadingIndicator } from '@components/loading-indicator';

const LoadingPage = ({ message }: { message?: string }) => {
  return (
    <AuthBackground page="loading">
      {!message ? (
        <ElevatedLoadingIndicator />
      ) : (
        <Paper elevation={2} sx={{ textAlign: 'center', padding: (theme) => theme.spacing(4) }}>
          <LoadingIndicator />
          <Typography variant="h3">{message}...</Typography>
        </Paper>
      )}
    </AuthBackground>
  );
};

export default LoadingPage;
