import { useState, useEffect, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import shortHash from 'short-hash';

import { useAuth } from './auth';

import {
  SelectedWarehouseFragment,
  useGetSelectedWarehouseQuery,
} from '@/graphql/defs/context/__generated__/warehouse-utils.generated';
import { GetSelectedWarehouse_defaultData } from '@/graphql/defs/context/warehouse-utils';
import {
  PermittedWarehouseFragment,
  PermittedWarehousesQuery,
  usePermittedWarehousesQuery,
} from '@/graphql/defs/list/__generated__/list-permitted-warehouses.generated';
import InitializeApp from '@components/router/initialize-app';
import { MAP_AREA, WAREHOUSE_BASE } from '@constants/routes';
import AppLoadingKeys from '@context/app-loading/AppLoadingKeys';
import { useAppLoadingToggle } from '@context/app-loading/AppLoadingToggleProvider';
import { useSnackbar } from '@context/snackbar';
import usePrevious from '@hooks/usePrevious';
import UnauthorizedWarehouse from '@pages/unauthorized-warehouse';

export const SESSION_WAREHOUSE_KEY = ':userId-session-warehouse';

export interface IWarehouseUtils {
  selectedOrganizationId: string;
  selectedWarehouseId?: string;
  selectedWarehouse?: SelectedWarehouseFragment;
  selectWarehouse: (warehouseId: string) => void;
  isLoadingSelectedWarehouse: boolean;
  permittedWarehouses: PermittedWarehousesQuery['permittedWarehouses'];
}
const WarehouseUtilsContext = createContext<IWarehouseUtils>(null);

// There is not multi-org functionality currently, but when we support it we can update selectedOrganizationId to help propagate across the app.
const organizationId = 'default';

const WarehouseUtilsProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const { showMessage } = useSnackbar();
  const { user } = useAuth();
  const { warehouseShortHash } = useParams();
  const { addAppLoadingRequest, removeAppLoadingRequest } = useAppLoadingToggle();

  useEffect(() => {
    addAppLoadingRequest(AppLoadingKeys.WarehouseUtils, t('appLoading.warehouseUtils'));
  }, []);

  const [permittedWarehouses, setPermittedWarehouses] =
    useState<PermittedWarehouseFragment[]>(null);
  usePermittedWarehousesQuery({
    onCompleted: ({ permittedWarehouses: _permittedWarehouses }) => {
      setPermittedWarehouses(_permittedWarehouses || []);
    },
  });

  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>(null);
  useEffect(() => {
    if (permittedWarehouses === null) return;
    if (permittedWarehouses.length > 0 && warehouseShortHash) {
      const warehouse = permittedWarehouses.find(
        (warehouse) => shortHash(warehouse.id) === warehouseShortHash,
      );

      if (warehouse) {
        if (
          sessionStorage.getItem(SESSION_WAREHOUSE_KEY.replace(':userId', user?.id)) !==
          warehouse.id
        ) {
          // In this event, we need to hard refresh the page after setting this value to session storage so that the Apollo Client creation can use this for x-warehouse-id.
          // This is to help handle cases where a user's first link to the app is a warehouse link.
          sessionStorage.setItem(SESSION_WAREHOUSE_KEY.replace(':userId', user?.id), warehouse.id);
          window.location.reload();
        }
        setSelectedWarehouseId(warehouse.id);
      } else {
        setSelectedWarehouseId('NOT_PERMITTED');
        removeAppLoadingRequest(AppLoadingKeys.WarehouseUtils);
      }
    } else {
      setSelectedWarehouseId('NO_PERMITTED_WAREHOUSES');
      removeAppLoadingRequest(AppLoadingKeys.WarehouseUtils);
    }
  }, [permittedWarehouses]);

  const [selectedWarehouse, setSelectedWarehouse] = useState<SelectedWarehouseFragment>(
    GetSelectedWarehouse_defaultData.warehouse,
  );

  const selectWarehouse = (warehouseId: string) => {
    // If the user is on the aisle map page, we need to redirect them to the area map page.
    // Otherwise, we just reload the page. We need to reload the page so the Apollo Client x-warehouse-id header is updated.
    const { pathname } = window.location;

    sessionStorage.setItem(SESSION_WAREHOUSE_KEY.replace(':userId', user?.id), warehouseId);

    if (/map\/area\/.*\/aisle/.test(pathname)) {
      window.location.href =
        window.location.origin +
        WAREHOUSE_BASE.replace(':warehouseShortHash', shortHash(warehouseId)) +
        MAP_AREA;
    } else {
      let warehouseCheck = new RegExp(/(\/w\/[a-zA-Z0-9]+)/i); // Looks for "/w/*" in the pathname
      let _pathname = window.location.pathname;
      if (warehouseCheck.test(_pathname)) {
        window.location.href =
          window.location.origin +
          _pathname.replace(
            warehouseCheck,
            WAREHOUSE_BASE.replace(':warehouseShortHash', shortHash(warehouseId)),
          );
      } else {
        window.location.href =
          window.location.origin +
          WAREHOUSE_BASE.replace(':warehouseShortHash', shortHash(warehouseId));
      }
    }
  };

  const { loading: isLoadingSelectedWarehouse } = useGetSelectedWarehouseQuery({
    skip:
      !user?.id ||
      !selectedWarehouseId ||
      ['NO_PERMITTED_WAREHOUSES', 'NOT_PERMITTED'].includes(selectedWarehouseId),
    fetchPolicy: 'cache-first',
    variables: { id: selectedWarehouseId },
    onCompleted: ({ warehouse }) => {
      setSelectedWarehouse(warehouse);
      removeAppLoadingRequest(AppLoadingKeys.WarehouseUtils);
    },
    onError: async (error) => {
      showMessage({ type: 'error', message: error.message });
      removeAppLoadingRequest(AppLoadingKeys.WarehouseUtils);
    },
  });

  if (permittedWarehouses === null || selectedWarehouseId === null) return null;
  return (
    <WarehouseUtilsContext.Provider
      value={{
        selectedOrganizationId: organizationId,
        selectedWarehouseId,
        selectedWarehouse,
        selectWarehouse,
        isLoadingSelectedWarehouse,
        permittedWarehouses,
      }}
    >
      {permittedWarehouses?.length > 0 &&
      !['NO_PERMITTED_WAREHOUSES', 'NOT_PERMITTED'].includes(selectedWarehouseId) ? (
        children
      ) : (
        <InitializeApp>
          <UnauthorizedWarehouse />
        </InitializeApp>
      )}
    </WarehouseUtilsContext.Provider>
  );
};

export default WarehouseUtilsProvider;

interface IUseWarehouseUtils {
  onWarehouseChange?: () => void;
}

export const useWarehouseUtils = ({ onWarehouseChange }: IUseWarehouseUtils = {}) => {
  const ctx = useContext(WarehouseUtilsContext);
  if (ctx === null) {
    throw new Error('useWarehouseUtils must be used within WarehouseProvider');
  }

  const { selectedWarehouseId } = ctx;
  const prevSelectedWarehouseId = usePrevious(selectedWarehouseId);

  useEffect(() => {
    if (
      prevSelectedWarehouseId &&
      prevSelectedWarehouseId !== selectedWarehouseId &&
      onWarehouseChange
    ) {
      onWarehouseChange();
    }
  }, [selectedWarehouseId]);

  return ctx;
};
