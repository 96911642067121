import { useContext, createContext, useRef } from 'react';

import Snackbar, { TShowMessage } from '@components/snackbar';

export interface ISnackbarContext {
  showMessage: TShowMessage;
}
const SnackbarContext = createContext<ISnackbarContext>(null);

const SnackbarProvider = ({ children }) => {
  const showMessageRef = useRef<TShowMessage>(() => {});

  const showMessage: TShowMessage = (message) => {
    showMessageRef.current(message);
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      <>
        {children}
        <Snackbar showMessageRef={showMessageRef} />
      </>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

export const useSnackbar = () => {
  const ctx = useContext(SnackbarContext);
  if (ctx === null) {
    throw new Error('useSnackbar must be used within SnackbarProvider');
  }

  return ctx;
};
