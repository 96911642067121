/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermittedWarehouseFragment = { __typename?: 'UserPermittedWarehousesResponseDto', id: string, code: string, name?: string | null };

export type PermittedWarehousesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PermittedWarehousesQuery = { __typename?: 'Query', permittedWarehouses: Array<{ __typename?: 'UserPermittedWarehousesResponseDto', id: string, code: string, name?: string | null }> };

export const PermittedWarehouseFragmentDoc = gql`
    fragment PermittedWarehouse on UserPermittedWarehousesResponseDto {
  id
  code
  name
}
    `;
export const PermittedWarehousesDocument = gql`
    query PermittedWarehouses {
  permittedWarehouses {
    ...PermittedWarehouse
  }
}
    ${PermittedWarehouseFragmentDoc}`;

/**
 * __usePermittedWarehousesQuery__
 *
 * To run a query within a React component, call `usePermittedWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermittedWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermittedWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermittedWarehousesQuery(baseOptions?: Apollo.QueryHookOptions<PermittedWarehousesQuery, PermittedWarehousesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermittedWarehousesQuery, PermittedWarehousesQueryVariables>(PermittedWarehousesDocument, options);
      }
export function usePermittedWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermittedWarehousesQuery, PermittedWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermittedWarehousesQuery, PermittedWarehousesQueryVariables>(PermittedWarehousesDocument, options);
        }
export type PermittedWarehousesQueryHookResult = ReturnType<typeof usePermittedWarehousesQuery>;
export type PermittedWarehousesLazyQueryHookResult = ReturnType<typeof usePermittedWarehousesLazyQuery>;
export type PermittedWarehousesQueryResult = Apollo.QueryResult<PermittedWarehousesQuery, PermittedWarehousesQueryVariables>;