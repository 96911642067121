import { Navigate, Outlet } from 'react-router-dom';

import { usePermissions } from '@context/permissions';
import UnauthorizedPage from '@pages/unauthorized-page';

const PageGuard = ({ guard, featureFlagEnabled = true, children = null }) => {
  const { permissions } = usePermissions();
  if (!featureFlagEnabled) {
    return <Navigate to="/" replace />;
  }
  return guard && !permissions[guard] ? <UnauthorizedPage /> : children || <Outlet />;
};

export default PageGuard;
