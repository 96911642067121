import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@context/auth';
import { SESSION_WAREHOUSE_KEY } from '@context/warehouse-utils';

const UnauthorizedWarehouse = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'unauthorizedWarehouse' });
  const { user } = useAuth();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '95vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '80px',
      }}
    >
      <Box>
        <img src={'/images/phil-unauthorized.svg'} height="400px" alt="support-message" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '420px', marginLeft: '80px' }}>
        <Box sx={{ fontSize: '42px', fontWeight: 600, color: '#4A4A4A' }}>{t('title')}</Box>
        <Box sx={{ fontSize: '18px', fontWeight: 400, color: '#4A4A4A', marginTop: '10px' }}>
          {t('subtitle')}
        </Box>
        <Button
          variant="contained"
          sx={{ margin: '50px auto 50px auto' }}
          onClick={() => {
            sessionStorage.removeItem(SESSION_WAREHOUSE_KEY.replace(':userId', user?.id));
            navigate('/');
          }}
        >
          {t('returnToDefaultWarehouse')}
        </Button>
      </Box>
    </Box>
  );
};

export default UnauthorizedWarehouse;
