import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import theme from '../theme';

import AuthProvider from './auth';
import ShortcutProvider from './shortcut';
import SnackbarProvider from './snackbar';

import AppLoadingToggleProvider from '@context/app-loading/AppLoadingToggleProvider';
import FeatureFlagsProvider from '@context/feature-flags';

const PreAuthProviders: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <AppLoadingToggleProvider>
      <ShortcutProvider>
        <SnackbarProvider>
          <FeatureFlagsProvider>
            <AuthProvider>{children}</AuthProvider>
          </FeatureFlagsProvider>
        </SnackbarProvider>
      </ShortcutProvider>
    </AppLoadingToggleProvider>
  </ThemeProvider>
);

export default PreAuthProviders;
