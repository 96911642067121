import axios, { AxiosInstance } from 'axios';
import { getAuth } from 'firebase/auth';

import { User } from '@/graphql/types.generated';
import { MAP_SAP_TOKEN } from '@constants/routes';

let hasRetrievedApiInfo = false;
let API_URL = process.env.REACT_APP_API_URL;
let API_KEY = process.env.REACT_APP_FB_API_KEY;
interface IHandshakeResponse {
  metadata?: {
    sap: {
      sapSso: 'true' | 'false';
      sapAuthorizationUrl: string;
      sapSsoStatus: 'active' | 'initiate';
    };
  };
  user?: User;
}

const getHeaders = async () => {
  const token = await getAuth()?.currentUser?.getIdToken();
  return {
    headers: {
      'x-api-key': API_KEY,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
};

const getApiInfo = async () => {
  if (process.env.REACT_APP_ENV === 'production' && !hasRetrievedApiInfo) {
    // For production/non-local development, we need to get the API URL from our express api.
    const { data: settings } = await axios.get<{
      apiUrl: string;
      apiKey: string;
    }>('/api/settings');
    API_URL = settings.apiUrl;
    API_KEY = settings.apiKey;
  }

  if (!hasRetrievedApiInfo) hasRetrievedApiInfo = true; // simply just to reduce our calls to this endpoint if we have already requested it.
};

const getApiClient = async (): Promise<AxiosInstance> => {
  await getApiInfo();
  const headers = await getHeaders();
  return axios.create({
    baseURL: API_URL,
    ...headers,
  });
};

/**
 * returns an authId to the api. This auth id is already
 * mapped to an sap token. This enpoint maps the use currently
 * logged in to that sap token.
 */
export const mapSapToken = async (authId) => {
  const client = await getApiClient();
  return client.post(MAP_SAP_TOKEN, {
    authId,
  });
};

export const getWebConfig = async () => {
  const client = await getApiClient();
  return client.get(`/web-configuration`);
};

/**
 * Sends firebase id token in Auth header in exchange for
 * a user object and metadata specifying whether further
 * auth action is required like authentication with sap.
 */
export const authHandshake = async (): Promise<{
  user?: User | undefined;
  authRedirect?: string | undefined;
}> => {
  const client = await getApiClient();
  const {
    data: {
      user,
      metadata: { sap },
    },
  } = await client.get<IHandshakeResponse>('/auth/handshake');
  if (sap?.sapSso === 'true' && sap?.sapSsoStatus === 'initiate') {
    return { authRedirect: sap.sapAuthorizationUrl };
  }
  return { user };
};

export default getApiClient;
