import { LicenseInfo } from '@mui/x-license-pro';
import { BrowserRouter } from 'react-router-dom';

import './i18n';

import { ErrorBoundary } from '@components/error-boundary';
import { PostAuthRouter } from '@components/router/post-auth-router';
import PreAuthProviders from '@context/pre-auth-providers';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <PreAuthProviders>
          <PostAuthRouter />
        </PreAuthProviders>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
