import { Box, Fade } from '@mui/material';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';

import {
  TAddAppLoadingRequest,
  TRemoveAppLoadingRequest,
} from '@context/app-loading/AppLoadingToggleProvider';
import LoadingPage from '@pages/loading';

interface IAppLoadingStateContext {
  isAppInitialized: boolean;
  isAppLoading: boolean;
}
export const AppLoadingStateContext = createContext<IAppLoadingStateContext>(null);

export interface TAppLoadingRequest {
  key: string;
  message: string;
}
export type SetAppInitialized = Dispatch<SetStateAction<boolean>>;
export type SetAppLoadingRequests = Dispatch<SetStateAction<TAppLoadingRequest[]>>;
interface IAppLoadingStateProviderProps {
  setAppInitializedRef: MutableRefObject<SetAppInitialized>;
  addAppLoadingRequestRef: MutableRefObject<TAddAppLoadingRequest>;
  removeAppLoadingRequestRef: MutableRefObject<TRemoveAppLoadingRequest>;
  children: ReactElement | ReactElement[];
}
const AppLoadingStateProvider = ({
  setAppInitializedRef,
  addAppLoadingRequestRef,
  removeAppLoadingRequestRef,
  children,
}: IAppLoadingStateProviderProps) => {
  const [isAppInitialized, setAppInitialized] = useState(false);
  const [appLoadingRequests, setAppLoadingRequests] = useState<TAppLoadingRequest[]>([]);

  const addAppLoadingRequest = useCallback(
    (key: string, message: string) => {
      setAppLoadingRequests((prev) => [...prev, { key, message }]);
    },
    [setAppLoadingRequests],
  );

  const removeAppLoadingRequest = useCallback(
    (key: string) => {
      setAppLoadingRequests((prev) => prev.filter((r) => r.key !== key));
    },
    [setAppLoadingRequests],
  );

  useEffect(() => {
    setAppInitializedRef.current = setAppInitialized;
    addAppLoadingRequestRef.current = addAppLoadingRequest;
    removeAppLoadingRequestRef.current = removeAppLoadingRequest;
  }, [setAppInitialized, setAppLoadingRequests]);

  return (
    <>
      <TransitionGroup>
        {(!isAppInitialized || !!appLoadingRequests[0]?.key) && (
          <Fade appear={false} style={{ transitionDuration: '1500ms' }}>
            <Box zIndex={1400}>
              <MemoizedFadeLoadingPage
                appLoadingRequestMessage={appLoadingRequests[0]?.message || null}
              />
            </Box>
          </Fade>
        )}
      </TransitionGroup>
      <AppLoadingStateContext.Provider
        value={{
          isAppInitialized,
          isAppLoading: appLoadingRequests.length > 0,
        }}
      >
        {children}
      </AppLoadingStateContext.Provider>
    </>
  );
};

export default AppLoadingStateProvider;

export const useAppLoadingState = () => {
  const ctx = useContext(AppLoadingStateContext);
  if (!ctx) {
    throw new Error('useAppLoadingState must be used within AppLoadingStateProvider');
  }
  return ctx;
};

const MemoizedFadeLoadingPage = ({
  appLoadingRequestMessage,
}: {
  appLoadingRequestMessage: string | null;
}) => {
  const { t } = useTranslation('pages');
  return <LoadingPage message={appLoadingRequestMessage || t('appLoading.initializingApp')} />;
};
