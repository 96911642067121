import { alpha, darken, lighten } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import { createTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type TLinkPropsMap = Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] };
const LinkBehavior = forwardRef<HTMLAnchorElement, TLinkPropsMap>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

enum Colors {
  aliceBlue = '#eef8ff',
  background = '#f3f3f3',
  error = '#b52b2b',
  errorAlt = '#eb5757',
  gainsboro = '#d8e0e5',
  slateGrey = '#6A6969',
  text = '#4A4A4A',
  textDark = '#363636',
  inbetweenGrey = '#b6b6b6',
  info = '#5eb1e3',
  lightWash = '#9fd6ff',
  navyBlue = '#256999',
  offwhite = '#EFF3F5',
  orange = '#f2994a',
  overlay = '#4d4d4d',
  primary = '#0a3a57',
  seaGreen = '#38d2d4',
  secondary = '#2b7cb5',
  success = '#219653',
  successAlt = '#6fcf97',
  warning = '#f2c94c',
  white = '#ffffff',
}

export const THEME_SPACING_BASE = 4;

const theme = createTheme({
  spacing: THEME_SPACING_BASE,
  palette: {
    contrastThreshold: 4.5,
    background: {
      default: Colors.background,
      paper: Colors.white,
    },
    text: {
      disabled: 'rgba(0,0,0,0.38)',
      primary: Colors.text,
      secondary: Colors.textDark,
    },
    primary: {
      main: Colors.primary,
      light: lighten(Colors.primary, 0.15),
      dark: darken(Colors.primary, 0.15),
      contrastText: '#ffffff',
    },
    secondary: {
      main: Colors.secondary,
      light: lighten(Colors.secondary, 0.15),
      dark: darken(Colors.secondary, 0.15),
      contrastText: '#333333',
    },
    inbetweenGrey: {
      main: Colors.inbetweenGrey,
      light: lighten(Colors.inbetweenGrey, 0.15),
      dark: darken(Colors.inbetweenGrey, 0.25),
      contrastText: '#333333',
    },
    info: {
      main: Colors.info,
      light: lighten(Colors.info, 0.15),
      dark: darken(Colors.info, 0.15),
      contrastText: '#ffffff',
    },
    success: {
      main: Colors.success,
      light: lighten(Colors.success, 0.15),
      dark: darken(Colors.success, 0.15),
      contrastText: '#151515',
    },
    successAlt: {
      main: Colors.successAlt,
      light: lighten(Colors.successAlt, 0.15),
      dark: darken(Colors.successAlt, 0.15),
      contrastText: '#333333',
    },
    warning: {
      main: Colors.warning,
      light: lighten(Colors.warning, 0.15),
      dark: darken(Colors.warning, 0.15),
      contrastText: '#555555',
    },
    error: {
      main: Colors.error,
      light: lighten(Colors.error, 0.15),
      dark: darken(Colors.error, 0.15),
      contrastText: '#ffffff',
    },
    errorAlt: {
      main: Colors.errorAlt,
      light: lighten(Colors.errorAlt, 0.15),
      dark: darken(Colors.errorAlt, 0.15),
      contrastText: '#151515',
    },
    aliceBlue: {
      main: Colors.aliceBlue,
      light: lighten(Colors.aliceBlue, 0.15),
      dark: darken(Colors.aliceBlue, 0.15),
      contrastText: '#555555',
    },
    gainsboro: {
      main: Colors.gainsboro,
      light: lighten(Colors.gainsboro, 0.15),
      dark: darken(Colors.gainsboro, 0.075),
      contrastText: '#555555',
    },
    slateGrey: {
      main: Colors.slateGrey,
      light: lighten(Colors.slateGrey, 0.15),
      dark: darken(Colors.slateGrey, 0.25),
      contrastText: '#555555',
    },
    lightWash: {
      main: Colors.lightWash,
      light: lighten(Colors.lightWash, 0.15),
      dark: darken(Colors.lightWash, 0.15),
      contrastText: '#555555',
    },
    navyBlue: {
      main: Colors.navyBlue,
      light: lighten(Colors.navyBlue, 0.15),
      dark: darken(Colors.navyBlue, 0.15),
      contrastText: '#ffffff',
    },
    offwhite: {
      main: Colors.offwhite,
      light: lighten(Colors.offwhite, 0.075),
      dark: darken(Colors.offwhite, 0.075),
      contrastText: '#555555',
    },
    orange: {
      main: Colors.orange,
      light: lighten(Colors.orange, 0.075),
      dark: darken(Colors.orange, 0.075),
      contrastText: '#333333',
    },
    overlay: {
      main: alpha(Colors.overlay, 0.69),
      light: lighten(alpha(Colors.overlay, 0.69), 0.075),
      dark: darken(alpha(Colors.overlay, 0.69), 0.075),
      contrastText: '#333333',
    },
    seaGreen: {
      main: Colors.seaGreen,
      light: lighten(Colors.seaGreen, 0.15),
      dark: darken(Colors.seaGreen, 0.15),
      contrastText: '#404040',
    },
    white: {
      main: Colors.white,
      light: lighten(Colors.white, 0.075),
      dark: darken(Colors.white, 0.075),
      contrastText: '#555555',
    },
  },
  typography: {
    fontFamily: 'Barlow, sans-serif',
    allVariants: {
      letterSpacing: 0,
    },
    h1: {
      fontSize: '42px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '32px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: 1.22,
    },
    body1: {
      fontSize: '18px',
      lineHeight: 1.22,
    },
    body2: {
      fontSize: '16px',
      lineHeight: 1.38,
    },
    body3: {
      fontSize: '14px',
      lineHeight: 1.57,
    },
    body4: {
      fontSize: '12px',
      lineHeight: 1.83,
    },
    body5: {
      fontSize: '10px',
    },
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: {
          paper: { elevation: 4 },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: THEME_SPACING_BASE * 2,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          body1: 'p',
          body2: 'p',
          body3: 'p',
          body4: 'p',
          body5: 'p',
          inherit: 'p',
        },
      },
    },
  },
});

export default theme;
