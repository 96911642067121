import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from './auth';
import { useWarehouseUtils } from './warehouse-utils';

import { useGetPermissionsLazyQuery } from '@/graphql/defs/context/__generated__/permissions.generated';
import AppLoadingKeys from '@context/app-loading/AppLoadingKeys';
import { useAppLoadingToggle } from '@context/app-loading/AppLoadingToggleProvider';
import { useSnackbar } from '@context/snackbar';

interface IPermissionsContext {
  permissions: {
    [key: string]: boolean;
  };
}

const PermissionsContext = createContext<IPermissionsContext>({
  permissions: {},
});

const PermissionsProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const { user } = useAuth();
  const { selectedWarehouseId } = useWarehouseUtils();
  const { showMessage } = useSnackbar();
  const { addAppLoadingRequest, removeAppLoadingRequest } = useAppLoadingToggle();

  const hasInitializedPermissions = useRef(false);
  const [hasLoadedPermissions, setHasLoadedPermissions] = useState(false);

  const [permissions, setPermissions] = useState({});
  const [getPermissions] = useGetPermissionsLazyQuery();
  const loadPermissions = async (warehouseId) => {
    addAppLoadingRequest(AppLoadingKeys.Permissions, t('appLoading.permissions'));
    try {
      const {
        data: { permissions },
      } = await getPermissions({
        variables: {
          warehouseId,
        },
      });
      await setPermissions(
        permissions.reduce((acc, { code }) => {
          acc[code] = true;
          return acc;
        }, {}),
      );
    } catch (e) {
      showMessage({ type: 'error', message: e.message });
    } finally {
      removeAppLoadingRequest(AppLoadingKeys.Permissions);
      setHasLoadedPermissions(true);
    }
    if (hasInitializedPermissions) hasInitializedPermissions.current = true;
  };

  useEffect(() => {
    if (user?.email) {
      loadPermissions(selectedWarehouseId);
    }
  }, [selectedWarehouseId, user?.email]);

  if (!hasLoadedPermissions) return null;
  return (
    <PermissionsContext.Provider value={{ permissions }}>{children}</PermissionsContext.Provider>
  );
};

export default PermissionsProvider;

export const usePermissions = () => {
  const ctx = useContext(PermissionsContext);
  if (ctx === null) {
    throw new Error('usePermissions must be used within PermissionsProvider');
  }

  return ctx;
};
