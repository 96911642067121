/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectedWarehouseFragment = { __typename?: 'Warehouse', id: string, code: string, name?: string | null, type?: Types.ModelWarehouseType | null, companyId: string, contactInfo?: { __typename?: 'ContactInfo', id: string, email?: string | null, phone?: string | null, fax?: string | null, street1?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null } | null, displayPreference?: { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, timezone: string } | null };

export type GetSelectedWarehouseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetSelectedWarehouseQuery = { __typename?: 'Query', warehouse: { __typename?: 'Warehouse', id: string, code: string, name?: string | null, type?: Types.ModelWarehouseType | null, companyId: string, contactInfo?: { __typename?: 'ContactInfo', id: string, email?: string | null, phone?: string | null, fax?: string | null, street1?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null } | null, displayPreference?: { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, timezone: string } | null } };

export const SelectedWarehouseFragmentDoc = gql`
    fragment SelectedWarehouse on Warehouse {
  id
  code
  name
  type
  companyId
  contactInfo {
    id
    email
    phone
    fax
    street1
    street2
    city
    state
    zip
    country
  }
  displayPreference {
    id
    currency
    dateTimeFormat
    language
    numberFormat
    timezone
  }
}
    `;
export const GetSelectedWarehouseDocument = gql`
    query GetSelectedWarehouse($id: ID!) {
  warehouse(id: $id) {
    ...SelectedWarehouse
  }
}
    ${SelectedWarehouseFragmentDoc}`;

/**
 * __useGetSelectedWarehouseQuery__
 *
 * To run a query within a React component, call `useGetSelectedWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedWarehouseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSelectedWarehouseQuery(baseOptions: Apollo.QueryHookOptions<GetSelectedWarehouseQuery, GetSelectedWarehouseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectedWarehouseQuery, GetSelectedWarehouseQueryVariables>(GetSelectedWarehouseDocument, options);
      }
export function useGetSelectedWarehouseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectedWarehouseQuery, GetSelectedWarehouseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectedWarehouseQuery, GetSelectedWarehouseQueryVariables>(GetSelectedWarehouseDocument, options);
        }
export type GetSelectedWarehouseQueryHookResult = ReturnType<typeof useGetSelectedWarehouseQuery>;
export type GetSelectedWarehouseLazyQueryHookResult = ReturnType<typeof useGetSelectedWarehouseLazyQuery>;
export type GetSelectedWarehouseQueryResult = Apollo.QueryResult<GetSelectedWarehouseQuery, GetSelectedWarehouseQueryVariables>;