import { Typography } from '@mui/material';

type TranslationComponents = 'bold' | 'break' | 'detailHeaderLabel';

const ComponentDefinitions: Record<TranslationComponents, React.ReactNode> = {
  bold: <strong />,
  break: <br />,
  detailHeaderLabel: (
    <Typography
      component="span"
      sx={{
        color: (theme) => theme.palette.secondary.main,
        fontSize: 'inherit',
        fontWeight: 'inherit',
      }}
    />
  ),
};

export const getTransComponents = (components: TranslationComponents[]) => {
  const selectedComponents = {};
  Object.keys(ComponentDefinitions).forEach((component: TranslationComponents) => {
    if (components.includes(component))
      selectedComponents[component] = ComponentDefinitions[component];
  });
  return selectedComponents;
};
