import { Typography as MUITypography, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type TVariants = 'headline' | 'tableHeader' | 'editable' | 'inlineLink';

interface IStyledTypography {
  styledVariant?: TVariants;
}

// const styledVariants: Record<TVariants, Record<string, any>> = {
const styledVariants = (theme: Theme): Record<TVariants, Record<any, any>> => ({
  headline: {
    fontSize: '40px',
    color: theme.palette.slateGrey.main,
    fontWeight: 700,
  },
  tableHeader: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#0A3A57',
  },
  editable: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  inlineLink: {
    display: 'inline-block',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
});

const Typography = styled(MUITypography, {
  shouldForwardProp: (prop) => prop !== 'styledVariant',
})<IStyledTypography>(({ theme, styledVariant }) => styledVariants(theme)[styledVariant] || {});

export default Typography;
