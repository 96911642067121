import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Collapse, Typography } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';

interface IMessage {
  type: AlertColor;
  title?: string;
  message: string | string[];
  duration?: number;
  expiration?: number;
  action?: JSX.Element;
}

export type TShowMessage = (message: IMessage) => void;
interface ISnackbar {
  showMessageRef: MutableRefObject<TShowMessage>;
}
const Snackbar = ({ showMessageRef }: ISnackbar) => {
  const [messages, setMessages] = useState<IMessage[]>([]);

  const showMessage = useCallback<TShowMessage>(
    ({ type = 'info' as AlertColor, duration = 10000, message, title, action }) => {
      setMessages((messages) => [
        ...messages.filter(({ expiration }) => expiration > Date.now()),
        {
          title,
          message,
          duration,
          type,
          expiration: Date.now() + duration,
          action,
        },
      ]);
    },
    [setMessages],
  );
  useEffect(() => {
    showMessageRef.current = showMessage;
  }, [showMessage]);

  const closeMessage = (index) => {
    setMessages(messages.filter(({ expiration }, i) => expiration > Date.now() && i !== index));
  };

  return messages.length > 0 ? (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: (theme) => theme.spacing(9),
        maxHeight: '100vh',
        zIndex: 3000,
        paddingTop: '25px',
        overflowY: 'hidden',
      }}
      data-testid="snackbar-notifs-container"
      data-testkey="notifs-count"
      data-testvalue={messages.length}
    >
      <Box display="flex" flexDirection="column" gap={5}>
        {messages.map((message, index) => (
          <Notification key={index} message={message} close={() => closeMessage(index)} />
        ))}
      </Box>
    </Box>
  ) : null;
};

const colors = {
  info: '#5eb1e3',
  error: '#b52b2b',
  warning: '#f2c94c',
  success: '#219653',
};
const icons = {
  info: (
    <InfoOutlinedIcon sx={{ fontSize: '24px', color: (theme) => theme.palette.common.white }} />
  ),
  error: (
    <ErrorOutlineIcon sx={{ fontSize: '24px', color: (theme) => theme.palette.common.white }} />
  ),
  warning: (
    <WarningAmberIcon sx={{ fontSize: '24px', color: (theme) => theme.palette.text.primary }} />
  ),
  success: (
    <CheckCircleOutlineOutlinedIcon
      sx={{ fontSize: '24px', color: (theme) => theme.palette.common.white }}
    />
  ),
  close: (
    <CloseOutlinedIcon sx={{ fontSize: '24px', color: (theme) => theme.palette.common.white }} />
  ),
};

interface ISnackbarNotification {
  message: IMessage;
  close: () => void;
}
const Notification = ({ message: _message, close }: ISnackbarNotification) => {
  const [closing, setClosing] = useState(false);

  const { type, duration, title, message, action } = _message;

  useEffect(() => {
    const closingTimeoutId = setTimeout(() => {
      setClosing(true);
    }, duration);
    const timeoutId = setTimeout(() => {
      close();
    }, duration + 500);
    return () => {
      clearTimeout(closingTimeoutId);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Collapse in={!closing}>
      <Box
        sx={{
          display: 'flex',
          gap: (theme) => theme.spacing(3),
          backgroundColor: colors[type],
          minWidth: '250px',
          maxWidth: '500px',
          padding: '15px',
          borderRadius: (theme) => theme.spacing(2),
          transition: 'max-height 0.5s linear, padding 0.5s linear, margin 0.5s linear',
        }}
        data-testid={`snackbar-notif-${type}`}
      >
        <Box>{icons[type]}</Box>
        <Box>
          <Typography
            variant="body2"
            mb={1}
            sx={{
              color: (theme) =>
                type !== 'warning' ? theme.palette.common.white : theme.palette.text.primary,
            }}
            data-testid={`snackbar-notif-${type}-title`}
          >
            {title || type.toUpperCase()}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) =>
                type !== 'warning' ? theme.palette.common.white : theme.palette.text.primary,
            }}
            data-testid={`snackbar-notif-${type}-message`}
          >
            {message}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>{action}</Box>
        <Box
          onClick={close}
          sx={{ cursor: 'pointer', marginLeft: '5px' }}
          data-testid="snackbar-notif-close-x"
        >
          {icons.close}
        </Box>
      </Box>
    </Collapse>
  );
};

export default Snackbar;
