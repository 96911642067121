import { useEffect } from 'react';

import { useAppLoadingToggle } from '@context/app-loading/AppLoadingToggleProvider';

const InitializeApp = ({ children }) => {
  const { setAppInitialized } = useAppLoadingToggle();

  useEffect(() => {
    setAppInitialized(true);
  }, []);

  return children;
};

export default InitializeApp;
