import { createContext, useContext, Dispatch, SetStateAction, useRef } from 'react';

import AppLoadingStateProvider from '@context/app-loading/AppLoadingStateProvider';

export type TAddAppLoadingRequest = (key: string, message: string) => void;
export type TRemoveAppLoadingRequest = (key: string) => void;
interface IAppLoadingToggleContext {
  setAppInitialized: (isAppInitialized: boolean) => void;
  addAppLoadingRequest: TAddAppLoadingRequest;
  removeAppLoadingRequest: TRemoveAppLoadingRequest;
}
const AppLoadingToggleContext = createContext<IAppLoadingToggleContext>(null);

const AppLoadingToggleProvider = ({ children }) => {
  const setAppInitializedRef = useRef<Dispatch<SetStateAction<boolean>>>(() => {});
  const setAppInitialized = (isAppInitialized: boolean) => {
    setAppInitializedRef.current(isAppInitialized);
  };

  const addAppLoadingRequestRef = useRef<TAddAppLoadingRequest>(() => {});
  const addAppLoadingRequest = (key: string, message: string) => {
    addAppLoadingRequestRef.current(key, message);
  };

  const removeAppLoadingRequestRef = useRef<TRemoveAppLoadingRequest>(() => {});
  const removeAppLoadingRequest = (key: string) => {
    removeAppLoadingRequestRef.current(key);
  };

  return (
    <>
      <AppLoadingToggleContext.Provider
        value={{
          setAppInitialized,
          addAppLoadingRequest,
          removeAppLoadingRequest,
        }}
      >
        <AppLoadingStateProvider
          setAppInitializedRef={setAppInitializedRef}
          addAppLoadingRequestRef={addAppLoadingRequestRef}
          removeAppLoadingRequestRef={removeAppLoadingRequestRef}
        >
          {children}
        </AppLoadingStateProvider>
      </AppLoadingToggleContext.Provider>
    </>
  );
};

export default AppLoadingToggleProvider;

export const useAppLoadingToggle = () => {
  const ctx = useContext(AppLoadingToggleContext);
  if (!ctx) {
    throw new Error('useAppLoadingToggle must be used within AppLoadingToggleProvider');
  }
  return ctx;
};
